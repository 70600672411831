import { Transition } from "@headlessui/react";
import clsx from "clsx";
import Link from "next/link";
import { ReactNode, useState } from "react";

import NavbarLinkButton from "components/atoms/NavbarLinkButton";
import NavbarSVLogoLink from "components/molecules/NavbarSVLogoLink";
import { NavbarMenuItem } from "components/organisms/Navbar/types";

import { useBackgroundOnScroll } from "lib/hooks";

interface MenuItemProps {
  text: string;
  isSelected?: boolean;
  onSelect?: () => void;
  url?: string;
  children?: ReactNode;
}

function MenuItem(props: MenuItemProps) {
  const [opened, toggle] = useState(false);
  return (
    <li
      onClick={() => {
        if (props.url) props.onSelect();
        toggle(!opened);
      }}
      className="border-b border-light-gray cursor-pointer py-4 text-green-black font-nouvelle text-xl relative"
    >
      <div className="flex justify-between items-center">
        {props.url ? (
          <Link href={props.url}>
            <a className="block w-full">{props.text}</a>
          </Link>
        ) : (
          <span>{props.text}</span>
        )}
        {props.children && (
          <div
            className={clsx(
              "flex w-6 h-6 items-center justify-center pointer-events-none",
              opened && "rotate-180"
            )}
          >
            <i className="fak fa-sv-down-arrow text-action-gray text-[.5rem] pointer-events-none"></i>
          </div>
        )}
      </div>
      <Transition
        show={opened}
        enter="transition-all duration-50"
        enterFrom="-translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-1"
        leave="transition-all duration-150"
        leaveFrom="translation-y-0 opacity-1"
        leaveTo="-translate-y-2 opacity-0"
        className="w-full pt-4"
      >
        {props.children}
      </Transition>
    </li>
  );
}

function SubMenuList(props: { children: ReactNode }) {
  return (
    <ul className="p-3 bg-white border border-warm-light rounded-3xl shadow-[0_4px_8px_rgba(0,0,0,0.08)]">
      {props.children}
    </ul>
  );
}

function SubMenuItem(props: MenuItemProps) {
  return (
    <li className="h-12 px-3 flex items-center">
      {props.url ? (
        <Link href={props.url}>
          <a
            className="block w-full p-2 rounded-lg hover:bg-warm-light"
            onClick={props.onSelect}
          >
            {props.text}
          </a>
        </Link>
      ) : (
        <span>{props.text}</span>
      )}
    </li>
  );
}
interface Props {
  items: NavbarMenuItem[];
}

function MobileMenu(props: Props) {
  const [open, toggle] = useState(false);
  const changeBackground = useBackgroundOnScroll(50);

  return (
    <div className="top-0 fixed w-full z-50 navbar-condensed:hidden">
      <nav
        className={clsx(
          "bg-warm-light flex items-center justify-between relative transition-colors h-14 p-4",
          changeBackground && !open && "bg-warm-light/70 backdrop-blur-md"
        )}
      >
        <div className="flex gap-3">
          <button
            className={clsx("flex", "items-baseline", open && "hidden")}
            onClick={() => toggle(true)}
          >
            <i className="fak fa-sv-hamburger text-sv-red text-xl"></i>
          </button>
          <button
            className={clsx("flex", "items-baseline", !open && "hidden")}
            onClick={() => toggle(false)}
          >
            <i className="fak fa-sv-exit text-sv-red text-xl"></i>
          </button>
          <NavbarSVLogoLink onClick={() => toggle(false)} height={21} />
        </div>
        <Transition
          show={!open}
          enter="transition-all duration-50"
          enterFrom="translate-x-1 opacity-0"
          enterTo="translate-x-0 opacity-1"
          leave="transition-all duration-50"
          leaveFrom="translation-x-0 opacity-1"
          leaveTo="translate-x-1 opacity-0"
          className="h-9 w-26 md:h-11 md:w-37"
        >
          <NavbarLinkButton type="primary" href="/apply-now">
            Apply Now
          </NavbarLinkButton>
        </Transition>
      </nav>
      <Transition
        show={open}
        as="ul"
        enter="transition-all duration-50"
        enterFrom="-translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-1"
        leave="transition-all duration-150"
        leaveFrom="translation-y-0 opacity-1"
        leaveTo="-translate-y-2 opacity-0"
        className="bg-warm-light h-[calc(100vh-3.5rem)] relative pt-2 pb-14 px-4 w-full"
      >
        {props.items.map((item) => (
          <MenuItem
            key={item.text}
            text={item.text}
            url={item.url}
            onSelect={() => toggle(false)}
          >
            {item.submenu && (
              <SubMenuList>
                {item.submenu.map((subItem) => {
                  return (
                    <SubMenuItem
                      key={subItem.text}
                      url={subItem.url}
                      text={subItem.text}
                      onSelect={() => toggle(false)}
                    />
                  );
                })}
              </SubMenuList>
            )}
          </MenuItem>
        ))}
      </Transition>
    </div>
  );
}

export default MobileMenu;
