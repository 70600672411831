import { config as fontAwesomeConfig } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import * as Sentry from "@sentry/nextjs";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import "polyfill-object.fromentries";
import { ComponentType, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import "styles/globals.css";

import GoogleScript from "components/atoms/GoogleScript";
import OneTrustScript from "components/atoms/OneTrust";
import ReferralRockScript from "components/atoms/ReferralRockScript";
import DefaultFooter from "components/organisms/Footer";
import DefaultNavbar from "components/organisms/Navbar";
import SVLogoBar from "components/templates/SVLogoBar";

import {
  getVariantValueWithTargetPages,
  isPathInPathList,
} from "lib/experiment-helper";
import { useFlagsmith, useVariant } from "lib/flagsmith/flagsmith-browser";
import { useFirstVisit, useKeepUTMs } from "lib/hooks";
import * as tracker from "lib/tracker";
import { getQueryFromRouter, hasOwnProperty } from "lib/utils";

import { LayoutConfig } from "types/theme.types";

// Prevent fontawesome from dynamically adding its css since we did it manually above
fontAwesomeConfig.autoAddCss = false;

// NOTE: We're using scope to cheat with react
let pageName: string;
export const HIDE_NAVBAR_EXPERIMENT_ID = "";

function useTrackPageView() {
  const router = useRouter();
  useEffect(() => {
    function handleRouteChangeComplete() {
      const facebook = !router.asPath.includes("/amazon");
      tracker.trackPageView(pageName, {}, { facebook });
      tracker.trackLastWebVisitUTMs();
    }

    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);
}

function shouldShow(
  key: keyof LayoutConfig,
  Component: ComponentType<unknown>
): boolean {
  return !(hasOwnProperty(Component, key) && Component[key] === false);
}

function App({ Component, pageProps }) {
  const router = useRouter();
  const query = getQueryFromRouter(router);

  const { isInitialized } = useFlagsmith();
  const hideNavbarVariant = useVariant(HIDE_NAVBAR_EXPERIMENT_ID);

  const hideByDefault = !shouldShow("showDefaultNavbar", Component);
  const [hideNavbar, setHideNavbar] = useState(true);

  useEffect(() => {
    const shouldHideNavbar = getVariantValueWithTargetPages(
      router.asPath,
      hideNavbarVariant,
      hideByDefault
    );

    setHideNavbar(shouldHideNavbar);
  }, [router.asPath, hideNavbarVariant]);

  useEffect(() => {
    const [pathName] = router.asPath.split("?");
    if (isInitialized) {
      tracker.trackExperiment(pathName);

      if (isPathInPathList(hideNavbarVariant?.targetPages, router.asPath)) {
        tracker.trackExperiment(HIDE_NAVBAR_EXPERIMENT_ID);
      }
    }
  }, [router.asPath, isInitialized]);

  if (typeof Component.generatePageName === "function") {
    pageName = Component.generatePageName({ query });
  } else {
    Sentry.captureMessage(
      `Component on ${router.pathname} has no generatePageName function, analytics won't work correctly!`,
      "warning"
    );
  }

  const externalName = Component.generateExternalPageName
    ? Component.generateExternalPageName({ query })
    : "SV Academy";

  const externalDescription = Component.generateExternalDescription
    ? Component.generateExternalDescription({ query })
    : "We elevate talented individuals from different backgrounds and connect them with trailblazing companies to launch their careers.";

  const navbar = !hideNavbar ? (
    <DefaultNavbar {...Component.navbarProps} />
  ) : (
    isPathInPathList(hideNavbarVariant?.targetPages, router.asPath) && (
      <SVLogoBar
        color="text-sv-red"
        bg="bg-warm-light"
        spacing={[{ type: "padding", step: 6, side: "top" }]}
      />
    )
  );

  const footer = shouldShow("showDefaultFooter", Component) && (
    <DefaultFooter />
  );

  useFirstVisit();
  useKeepUTMs(); // useKeepUTMs needs to run before useTrackPageView so it can use getUTMSFromStorage
  useTrackPageView();
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
  }, []);

  return (
    <>
      <Head>
        <title>{externalName}</title>
        <meta name="description" content={externalDescription} />
      </Head>
      <Script
        src="https://kit.fontawesome.com/638a2c77ab.js"
        crossOrigin="anonymous"
      />
      <GoogleScript />
      <OneTrustScript />
      <Script src="//nexus.ensighten.com/choozle/17362/Bootstrap.js" />
      <ReferralRockScript />

      {navbar}
      <Component {...pageProps} />
      {footer}
    </>
  );
}

export default App;
