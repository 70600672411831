import clsx from "clsx";

import { BackgroundColors, TextColors } from "types/theme.types";

const icons = {
  "arrow right": "fak fa-sv-arrow-right",
  briefcase: "fak fa-briefcase",
  close: "fak fa-close",
  compass: "fak fa-sv-compass",
  door: "fak fa-sv-door",
  exit: "fak fa-sv-exit",
  fast: "fak fa-sv-fast",
  filter: "fak fa-sv-filter",
  fist: "fak fa-sv-fist",
  hamburger: "fak fa-sv-hamburger",
  lightning: "fak fa-sv-lightning",
  menu: "fak fa-sv-menu",
  chevron: "fak fa-sv-menu-chevron",
  "chevron left": "fak fa-sv-menu-chevron-left",
  "chevron right": "fak fa-sv-menu-chevron-right",
  money: "fak fa-sv-money",
  "number 1": "fak fa-sv-number-1",
  "number 2": "fak fa-sv-number-2",
  "number 3": "fak fa-sv-number-3",
  "number 4": "fak fa-sv-number-4",
  overlap: "fak fa-sv-overlap",
  rocket: "fak fa-sv-rocket",
  stairs: "fak fa-sv-stairs",
  trained: "fak fa-sv-trained",
} as const;

const spacingSteps = [
  0, 2, 4, 5, 6, 8, 10, 11, 12, 14, 16, 20, 24, 36,
] as const;

const textField = (hasErrors) =>
  clsx(
    "block",
    "border",
    "font-content",
    "font-medium",
    "h-12",
    "p-3",
    "rounded-md",
    "text-xl",
    "w-full",
    "placeholder-primary",
    "placeholder:font-normal",
    "disabled:opacity-50",
    hasErrors
      ? ["border-error", "focus:border-error", "focus:ring-error"]
      : [
          "border-action-gray",
          "focus:border-green-gray",
          "focus:ring-green-gray",
        ],
    hasErrors ? "text-error" : "text-green-gray"
  );

const secondaryLabel = () =>
  clsx("font-content", "text-medium-gray", "text-sm");

const textAreaField = (hasErrors) =>
  clsx(
    "block",
    "border",
    "font-content",
    "font-medium",
    "p-3",
    "rounded-md",
    "text-xl",
    "h-64",
    "w-full",
    "placeholder-primary",
    "placeholder:font-normal",
    "disabled:opacity-50",
    hasErrors
      ? ["border-error", "focus:border-error", "focus:ring-error"]
      : [
          "border-action-gray",
          "focus:border-green-gray",
          "focus:ring-green-gray",
        ],
    hasErrors ? "text-error" : "text-green-gray"
  );

const fileField = (hasErrors: boolean, isDisabled: boolean) =>
  clsx(
    "block",
    "border",
    "font-content",
    "font-medium",
    "p-2",
    "rounded-md",
    "text-xl",
    "w-full",
    hasErrors
      ? ["border-error", "focus:border-error", "focus:ring-error"]
      : [
          "border-action-gray",
          "focus:border-green-gray",
          "focus:ring-green-gray",
        ],
    hasErrors ? "text-error" : "text-green-gray",
    isDisabled && "opacity-50"
  );

const submit = (
  isDisabled: boolean,
  color: TextColors,
  bgColor: BackgroundColors
) =>
  clsx(
    "font-bold",
    "font-content",
    "h-13",
    "rounded-lg",
    "text-center",
    "text-content",
    "text-lg",
    "w-full",
    isDisabled
      ? ["bg-light-gray", "rounded-sm", "text-action-gray"]
      : [color ?? "bg-yellow-pale", bgColor ?? "text-green-black"]
  );

export type ButtonType = "primary" | "secondary" | "disabled";
const button = (type: ButtonType) =>
  clsx(
    "p-3",
    "cursor-pointer",
    "font-content",
    "font-medium",
    "flex",
    "justify-center",
    "items-center",
    "h-full",
    "rounded-lg",
    "text-center",
    "text-sm",
    "w-full",
    type === "primary" && ["bg-yellow-pale", "text-green-black"],
    type === "secondary" && [
      "bg-warm-light",
      "border-2",
      "border-green-black",
      "text-green-black",
    ],
    type === "disabled" && ["bg-light-gray", "text-action-gray"]
  );

const salesButton = () =>
  clsx(button("primary"), "max-w-md", "px-6", "py-4", "text-xl");

const navbarButton = (type: ButtonType) =>
  clsx(button(type), "sticky", "top-0");

const selectBox = (hasErrors, isTouched = true) =>
  clsx(
    "block",
    "border",
    "font-content",
    "font-medium",
    "h-12",
    "overflow-hidden",
    "pr-9",
    "pl-3",
    "py-2",
    "rounded-md",
    "text-xl",
    "w-full",
    hasErrors
      ? ["border-error", "focus:border-error", "focus:ring-error", "text-error"]
      : [
          "border-action-gray",
          "focus:border-green-gray",
          "focus:ring-green-gray",
          isTouched ? "text-green-gray" : "text-gray-400",
        ]
  );

const gradientContainer = (paddingClassName: string) =>
  clsx(
    "absolute",
    "bg-gradient-to-t",
    "bottom-0",
    "from-black",
    "left-0",
    "to-transparent",
    "w-full",
    paddingClassName ?? "p-6 md:p-8"
  );

type RadioGroupOptionParams = {
  active: boolean;
  checked: boolean;
  disabled: boolean;
};
const radioGroupOption = (
  { active, checked, disabled }: RadioGroupOptionParams,
  ...classes: string[]
) =>
  clsx(
    active &&
      "ring-2 ring-offset-1 ring-offset-green-gray ring-white ring-opacity-60",
    disabled
      ? "border border-light-gray border-dashed text-action-gray"
      : "cursor-pointer",
    checked
      ? "bg-green-gray bg-opacity-10 border-2 border-green-gray text-green-gray"
      : "bg-white border border-light-gray text-medium-gray",
    "relative rounded-md py-3 px-4 flex focus:outline-none",
    classes
  );

export type NavbarBgColors = {
  defaultColor?: BackgroundColors;
  alternateColor?: BackgroundColors;
};
const navbar = (
  showAlternateColor: boolean,
  mobile = false,
  colors: NavbarBgColors = {
    defaultColor: "bg-transparent",
    alternateColor: "bg-warm-light",
  }
) => ({
  container: clsx(
    "top-0",
    mobile ? "lg:hidden" : "lg:block hidden",
    "fixed",
    "w-full",
    "z-50"
  ),
  nav: clsx(
    "flex",
    "items-center",
    "justify-between",
    "relative",
    "transition-colors",
    mobile ? "h-14 md:h20 p-4" : "h-20 px-12 z-10",
    showAlternateColor ? colors.alternateColor : colors.defaultColor
  ),
  optionsMenu: clsx("flex gap-3", !mobile ? "md:gap-7" : ""),
});

export type Spacing = {
  type: "padding";
  step: (typeof spacingSteps)[number];
  side: "top" | "bottom" | "y axis";
  breakpoint_prefix?: "sm" | "md" | "lg" | "xl" | "2xl";
};

const spacing = (spacingList: Spacing[]) => {
  const spacingSideMap = {
    top: "t",
    bottom: "b",
    "y axis": "y",
  } as const;

  const spacingTypeMap = {
    padding: "p",
  } as const;

  return clsx(
    spacingList?.map(
      ({ type, step, side, breakpoint_prefix }) =>
        `${breakpoint_prefix ? breakpoint_prefix + ":" : ""}${
          spacingTypeMap[type]
        }${spacingSideMap[side]}-${step}`
    )
  );
};

const textLink = clsx("underline", "hover:text-green-bright");

export default {
  button,
  fileField,
  gradientContainer,
  icons,
  navbar,
  navbarButton,
  radioGroupOption,
  salesButton,
  secondaryLabel,
  selectBox,
  spacing,
  spacingSteps,
  submit,
  textAreaField,
  textField,
  textLink,
};
