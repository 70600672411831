import DesktopMenu from "components/organisms/Navbar/DesktopMenu";
import MobileMenu from "components/organisms/Navbar/MobileMenu";
import { NavbarMenuItem } from "components/organisms/Navbar/types";

import { NavbarBgColors } from "lib/classnames";

const MENU_ITEMS: NavbarMenuItem[] = [
  {
    text: "Why SV Academy",
    submenu: [
      { text: "Program overview", url: "/program" },
      { text: "Meet our graduates", url: "/success-stories" },
      { text: "Get a brochure", url: "/brochure" },
    ],
  },
  { text: "Why sales", url: "/why-tech-sales" },
  {
    text: "Tuition",
    url: "/tuition",
  },
  {
    text: "Hire SV Grads",
    url: "/employers",
  },
];

type Props = {
  bgColors: NavbarBgColors;
};

export default function Navbar(props: Props) {
  return (
    <>
      <DesktopMenu items={MENU_ITEMS} {...props} />
      <MobileMenu items={MENU_ITEMS} {...props} />
    </>
  );
}
