import * as Sentry from "@sentry/nextjs";
import { CONSENT } from "data/consent";
import assign from "lodash/assign";
import omit from "lodash/omit";

import { Services } from "config/environment";

import { parseLinkedInProfile } from "lib/linkedin-validation";
import { path, removeNullishEntries, snakeCaseObject } from "lib/utils";

import { AssessmentRawPayload } from "types/assessment.types";
import { ProgramType, UTMs } from "types/common.types";
import {
  EmployersFormData,
  EmployersFormRawPayload,
} from "types/employers.types";

const { API_HOST } = process.env;

export const validateUUID = async (uuid: string) => {
  if (uuid) {
    const validationUrl = path.join(
      API_HOST,
      Services.UnifiedForm2021v2.Url,
      uuid
    );

    return fetch(validationUrl, {
      method: "GET",
    });
  }
};

export const getApplicantByUUID = async (uuid: string) => {
  if (uuid) {
    const getApplicantUrl = path.join(
      API_HOST,
      Services.GetApplicants.Url,
      uuid
    );
    return fetch(getApplicantUrl, {
      method: "GET",
    });
  }
};

export const getEmployerByUUID = async (uuid: string) => {
  if (uuid) {
    const getEmployerUrl = path.join(API_HOST, Services.GetEmployer.Url, uuid);
    return fetch(getEmployerUrl, {
      method: "GET",
    });
  }
};

export const getOfferDetailsByApplicationId = async (applicationId: string) => {
  if (applicationId) {
    const getOfferDetailsUrl = path.join(
      API_HOST,
      Services.GetOfferDetails.Url,
      applicationId
    );
    return fetch(getOfferDetailsUrl, { method: "GET" });
  }
};

export const generateCheckoutTuitionLink = async (applicationId: string) => {
  if (applicationId) {
    const checkoutTuitionLinkUrl = path.join(
      API_HOST,
      Services.CheckoutSessionGenerator.Url,
      applicationId
    );

    return await fetch(checkoutTuitionLinkUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
  }
};

// Application Flow
export const generateApplicationData = (data) => {
  if (data) {
    let processedData = data;

    if (data.applicationStep === 1) {
      processedData = {
        ...processedData,
        consent_text: CONSENT,
        sms_opt_in: true,
      };
    }

    processedData = assign(processedData, {
      isUnsureOfStartDate: data.date ? data.date === "later" : null,
      isUnsureOfProgram: data.programType
        ? data.programType === "unsure"
        : null,
      programType: data.programType === "unsure" ? null : data.programType,
      zip_code: data.zip,
      resume: data.resume,
    });
    processedData = omit(processedData, [
      "date",
      "zip",
      "resumeUrl",
      "highestEducationLevel",
      "referrerOther",
      "referrerSelect",
    ]);
    // To ignore cases where LinkedIn is optional or is blank
    if (processedData.linkedin) {
      // To deal with the empty value of `website/components/formQuestions/LinkedInInput.tsx`
      if (processedData.linkedin === "https://www.linkedin.com/in/") {
        processedData.linkedin = null;
      }
      // All other cases
      else {
        const profile = parseLinkedInProfile(processedData.linkedin);
        if (profile) {
          processedData.linkedin = `https://linkedin.com/in/${profile}`;
        } else {
          Sentry.captureException(
            new Error("LinkedIn value was invalid, ignoring"),
            {
              level: "warning",
              extra: {
                linkedin: processedData.linkedin,
              },
            }
          );
          processedData.linkedin = null;
        }
      }
    } else {
      processedData.linkedin = null;
    }
    if (
      typeof processedData.resume === "string" &&
      processedData.resume.length === 0
    ) {
      processedData.resume = null;
    }
    if (processedData.cohortNumber === 0) {
      processedData.cohortNumber = null;
    }
    processedData = removeNullishEntries(processedData);
    processedData = snakeCaseObject(processedData);
    return processedData;
  }
};

export const submitApplication = async ({
  uuid,
  payload,
}: {
  uuid: string;
  payload: object;
}) => {
  const submissionUrl = path.join(
    API_HOST,
    Services.UnifiedForm2021v2.Url,
    uuid
  );

  try {
    return await fetch(submissionUrl, {
      method: uuid ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  } catch (e) {
    Sentry.captureException(e, {
      level: "error",
      extra: {
        uuid,
        payload,
      },
    });
    throw e;
  }
};

export const generateEmployersFormPayload = (
  payload: EmployersFormRawPayload,
  lead_source: string
) => {
  return { ...snakeCaseObject(payload), lead_source } as EmployersFormData;
};

export const submitEmployersForm = async (payload: {
  data: ReturnType<typeof generateEmployersFormPayload>;
  utm_data: UTMs;
}): Promise<Response> => {
  const employersFormUrl = path.join(API_HOST, Services.EmployersApplyForm.Url);

  return fetch(employersFormUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

// Assessment Flow
export const generateAssessmentPayload = (
  uuid: string,
  data: AssessmentRawPayload,
  program: ProgramType
) =>
  removeNullishEntries({
    assessment_question: data.assessment_question,
    assessment_answer: data.assessment_answer,
    next_role_question: data.next_role_question,
    next_role_answer: data.next_role_answer,
    current_income_per_year: data.current_income_per_year,
    gender: data.gender === "Other" ? data.gender_other : data.gender,
    ethnicity:
      data.ethnicity === "Other" ? data.ethnicity_other : data.ethnicity,
    school: data.school,
    major: data.major,
    // TODO: Wait till we get rid of the assessment page in v1, and then make the change on both backend and frontend
    fellowship_opp_external_id: uuid || null, // This could be renamed to core_application_id
    // fellowship_opp_id: props.opportunityId || null, //To consider, will we keep receiving this option?
    program_type: program !== "unsure" ? program : "sdr",
  });

export const submitAssessment = async (
  payload: ReturnType<typeof generateAssessmentPayload>,
  requestWithPUT = false
): Promise<Response> => {
  const submissionUrl = path.join(
    API_HOST,
    Services.UnifiedAssessment2021v1.Url
  );

  return fetch(submissionUrl, {
    method: requestWithPUT ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
    }),
  });
};
