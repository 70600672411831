export const Services = {
  UnifiedAssessment2021v1: {
    Url: `forms/unified_assessment_2021_1`,
  },
  UnifiedForm2021v2: {
    Url: "forms/unified_apply_2021_2",
  },
  EmployersApplyForm: {
    Url: "employers/apply",
  },
  GetApplicants: {
    Url: "forms/applicant_data",
  },
  GetEmployer: {
    Url: "forms/employer_data",
  },
  GetOfferDetails: {
    Url: "offers/offer_details",
  },
  CheckoutSessionGenerator: {
    Url: "payments/page_generator",
  },
};
