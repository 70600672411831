import clsx from "clsx";
import Link from "next/link";
import { ReactNode, useState } from "react";

import NavbarLinkButton from "components/atoms/NavbarLinkButton";
import NavbarSVLogoLink from "components/molecules/NavbarSVLogoLink";
import { NavbarMenuItem } from "components/organisms/Navbar/types";

import { useBackgroundOnScroll } from "lib/hooks";

interface Props {
  items: NavbarMenuItem[];
}

interface MenuListProps {
  children: ReactNode;
}

function MenuList(props: MenuListProps) {
  return (
    <ul className="w-full flex gap-3 items-center justify-end pr-3 navbar-standard:gap-6 navbar-standard:pr-6">
      {props.children}
    </ul>
  );
}

interface MenuItemProps {
  text: string;
  isSelected?: boolean;
  onSelect?: () => void;
  url?: string;
  children?: ReactNode;
}

function MenuItem(props: MenuItemProps) {
  const [opened, setOpened] = useState(false);

  return (
    <li
      onMouseOver={() => {
        setOpened(true);
        props.onSelect();
      }}
      onMouseLeave={() => {
        setOpened(false);
      }}
      onClick={() => setOpened(!opened)}
      className={clsx(
        "font-nouvelle text-sm flex relative cursor-pointer navbar-standard:text-base transition-colors",
        props.isSelected ? "text-green-black" : "text-green-black/50"
      )}
    >
      {props.url ? (
        <Link href={props.url}>
          <a>{props.text}</a>
        </Link>
      ) : (
        <span className="pointer-events-none">{props.text}</span>
      )}
      {props.children && (
        <>
          <div className="flex w-5 h-5 items-center justify-center opacity-50 pointer-events-none">
            <i className="fak fa-sv-down-arrow text-[.4rem] pointer-events-none"></i>
          </div>
          {opened && props.children}
        </>
      )}
    </li>
  );
}

function SubMenuList(props: { children: ReactNode }) {
  return (
    <div className="absolute left-0 top-6 z-10 grid justify-center w-full">
      <ul className="p-3 bg-white border border-warm-light rounded-2xl shadow-[0_4px_8px_rgba(0,0,0,0.08)] w-[224px]">
        {props.children}
      </ul>
    </div>
  );
}

function SubMenuItem(props: MenuItemProps) {
  return (
    <li>
      {props.url ? (
        <Link href={props.url}>
          <a
            onClick={props.onSelect}
            className="block p-2 rounded-lg hover:bg-warm-light"
          >
            {props.text}
          </a>
        </Link>
      ) : (
        <span className="block p-2 rounded-lg hover:bg-warm-light">
          {props.text}
        </span>
      )}
    </li>
  );
}

function DesktopMenu(props: Props) {
  const [currentSection, setSection] = useState(null);
  const changeBackground = useBackgroundOnScroll(50);

  return (
    <div
      onMouseLeave={() => setSection(null)}
      className="top-0 fixed w-full z-50 hidden navbar-condensed:block"
    >
      <nav
        className={clsx(
          "bg-warm-light relative px-8 h-20 flex gap-2 items-center justify-between transition-all navbar-standard:px-12",
          changeBackground && "bg-warm-light/70 backdrop-blur-md"
        )}
      >
        <NavbarSVLogoLink />
        <MenuList>
          {props.items.map((item) => {
            return (
              <MenuItem
                key={item.text}
                isSelected={!currentSection || currentSection === item.text}
                onSelect={() => setSection(item.text)}
                text={item.text}
                url={item.url}
              >
                {item.submenu && (
                  <SubMenuList>
                    {item.submenu.map((subItem) => {
                      return (
                        <SubMenuItem
                          key={subItem.text}
                          url={subItem.url}
                          text={subItem.text}
                          onSelect={() => setSection(null)}
                        />
                      );
                    })}
                  </SubMenuList>
                )}
              </MenuItem>
            );
          })}
        </MenuList>
        <div className="h-11 w-37">
          <NavbarLinkButton type="primary" href="/apply-now">
            Apply Now
          </NavbarLinkButton>
        </div>
      </nav>
    </div>
  );
}

export default DesktopMenu;
